import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RestartService {

  restartApp() {
    location.reload();
  }
}
