import {
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  HostBinding,
  HostListener,
  inject,
  Injector,
  Input, OnChanges,
  OnInit,
  Optional,
  Output,
  signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule
} from '@angular/forms';
import { SelectModel } from './select_model';
import { isEqual } from "lodash";
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';
import { ApplyPipe } from 'ngxtension/call-apply';
import { ValidationComponent } from '../validation/validation.component';
import { LucideAngularModule } from 'lucide-angular';
import { NgClass } from '@angular/common';
import { LabelComponent } from '../label/label.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ApplyPipe, ValidationComponent, LucideAngularModule, NgClass, LabelComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    }
  ]
})
export class SelectComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() label!: string;
  @Input() name!: string;
  @Input() placeholder!: string;
  @Input() options!: SelectModel[];
  @Input() errormessage!: string;
  @Input() form!: FormGroup;
  @Input() span!: number;
  @Input() typeconv = true;
  @Input() value!: any
  @Input() hostStyleClass: string = '';
  @Input() disabled!: boolean;
  @Input() readonly!: boolean;
  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  formControl!: NgControl | null;
  showOptions = signal(false);
  focusIndex = 0;
  darkModeService: DarkModeService = inject(DarkModeService)


  @HostBinding('class') get t() {
    return `p-0 border-0 bg-transparent ${this.hostStyleClass.replace(/'/g, '')} sm:col-span-` + this.span;
  };

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.showOptions()) {
      this.closeOptions();
    }

  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeOptions();
    }
  }

  @ViewChild('Select') selectElement!: ElementRef;
  @ViewChild('Placeholder') placeholderElement!: ElementRef;

  constructor(
    private injector: Injector,
    private elementRef: ElementRef,
    @Optional() public formDirective: FormGroupDirective
  ) {
    this.formDirective = formDirective;
  }

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  get submitted() {
    return this.formDirective ? this.formDirective.submitted : false;
  }

  ngOnInit() {
    this.formControl = this.ngControl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl && !isEqual(changes?.['value']?.previousValue, changes?.['value']?.currentValue)) {
      this.value = this.ngControl?.value;
      this.onChange(this.value);
      this.onTouched();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  optionSelected(option: any) {
    this.closeOptions();
    this.changed(option);
  }

  changed(option: any) {
    // Check if the option is a number and not a boolean
    const isNumber = !isNaN(option) && typeof option !== 'boolean';
    // Convert the option to a number if it is a number
    const value = isNumber && this.typeconv ? Number(option) : option;

    if (option !== this.value) {
      this.value = value;
      this.onChange(this.value);
    }
    this.onTouched();
  }

  blur() {
    this.onTouched();
  }

  toggleShowOptions(event?: Event) {
    this.showOptions.update((prev) => !prev);
  }

  closeOptions(event?: Event) {
    // Outside click will be ignored if these inner elements are clicked.
    if (event?.target === this.selectElement?.nativeElement) return;
    if (event?.target === this.placeholderElement?.nativeElement) return;

    if (!this.showOptions()) return;
    this.showOptions.set(false);
  }

  getValueName(value: any, options: SelectModel[]) {
    if (!options || options.length === 0) return undefined;
    return options.find((option) => option.value === value)?.name || undefined;
  }
}
