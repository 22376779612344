import { Injectable, inject } from "@angular/core";
import { ClientService } from "../client/client.service";
import { Observable, switchMap, of } from "rxjs";
import { consultationinternal, consultationexternal, scinternal, entities } from "../client/client";

@Injectable({
  providedIn: 'root'
})
export class ConsultationService {
  clientService = inject(ClientService);

  public GetAllConsultations(consultationsRequest: consultationinternal.GetConsultationsQueryParams): Observable<consultationinternal.GetAllConsultationsResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.consultationinternal.ListConsultations(consultationsRequest)));
  }

  public CreateConsultation(consult: consultationinternal.CreateConsultationRequest): Observable<consultationinternal.CreateConsultationResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CreateConsultation(consult)))
  }

  public GetConsultation(docId: string): Observable<consultationinternal.GetConsultationResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.GetConsultation(docId)))
  }

  public UpdateConsultation(docId: string, userId: string, treatmentPlan: string, basketUrl: string, resources: string[], internal_resources: string[], notes: string, products: Array<any>, outcome?: consultationinternal.OutcomeSubmission, prescription?: consultationinternal.PrescriptionSubmission): Observable<any> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.UpdateConsultation(docId, {
      staff_id: userId,
      treatment_plan: treatmentPlan,
      basket_url: basketUrl,
      resources: resources,
      internal_resources: internal_resources,
      notes: notes,
      recommended_products: products,
      outcome: outcome,
      prescription: prescription
    })))
  }

  public UpdateConsultationState(docId: string, state: string): Observable<any> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.UpdateConsultationState(docId, {
      state: state,
    })))
  }

  public ListTreatmentPlanTemplates(): Observable<consultationinternal.ListTreatmentPlanTemplatesResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.ListTreatmentPlanTemplates()))
  }

  public CreateTreatmentPlanTemplate(treatmentPlan: consultationinternal.CreateTreatmentPlanTemplateRequest): Observable<consultationinternal.CreateTreatmentPlanTemplateResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CreateTreatmentPlanTemplate(treatmentPlan)))
  }

  public UpdateTreatmentPlanTemplate(docId: string, template: consultationinternal.EditTreatmentPlanTemplateRequest): Observable<consultationinternal.EditTreatmentPlanTemplateResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.EditTreatmentPlanTemplate(docId, template)));
  }

  public DeleteTreatmentPlanTemplate(docId: string): Observable<any> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.DeleteTreatmentPlanTemplate(docId)));
  }

  public GetConsultationIdByAppointment(appointmentId: string): Observable<consultationinternal.GetConsultationByAppointmentResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.GetConsultationByAppointment(appointmentId)))
  }

  public CreateConsultationReasons(consultationID: string, reasons: any): Observable<any> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CreateReasons(consultationID, reasons)))
  }

  public TerminateConsultation(consultationID: string, formName: string, formData: consultationinternal.FormData): Observable<any> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.SetTerminatedConsultationState(consultationID, {
      form_data: formData,
      form_name: formName
    })))
  }

  public GetPetConsultationsHistory(petDocId: string): Observable<consultationinternal.GetPetConsultationsHistoryResposnse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.GetPetConsultationsHistory(petDocId)))
  }

  public GetSymptomCheckerOverview(sessionId: string): Observable<scinternal.SymptomCheckerSummary> {
    return this.clientService.client.pipe(switchMap(client => client.scinternal.GetSessionSymptonCheckerSummary(sessionId)));
  }

  public CreateClinicalHistoryReport(petId: string): Observable<consultationexternal.CreateClinicalHistoryReportResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationexternal.CreateClinicalHistoryReport({ pet_doc_id: petId })));
  }

  public CreateAIClinicalNote(docID: string, treatmentPlan: consultationinternal.GenerateClinicalNoteRequest): Observable<consultationinternal.GenerateClinicalNoteResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.GenerateClinicalNote(docID, treatmentPlan)));
  }

  public CreatePrescriptionPDF(consultationID: string, prescriptionSubmission: consultationinternal.PrescriptionFormSubmission): Observable<consultationinternal.CreatePrescriptionPDFResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CreatePrescriptionPDF(consultationID, { prescription: prescriptionSubmission })))
  }

  public CreateHealthCheckReport(docID: string, params: consultationinternal.HealthCheckReportRequest): Observable<consultationinternal.HealthCheckReportResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CreateHealthCheckReport(docID, params)));
  }

  public GetPetFullConsultationsHistory(pagination: any, petDocId: string): Observable<consultationinternal.PaginatedConsultationHistory> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.GetPaginatedConsultationsHistoryByPetID(petDocId, pagination)))
  }

  public CreateShopifyCartLink(consultationID: string, params: entities.PrescriptionCartRequest): Observable<consultationinternal.PrescriptionCartResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CreateShopifyCartLink(consultationID, params)))
  }

  public UploadPatientMedia(params: consultationinternal.UploadPatientFileRequest): Observable<consultationinternal.UploadPatientFileResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.UploadPatientMediaFile(params)));
  }

  public GetPatientMediaFiles(params: consultationinternal.ListPatientMediaFilesRequest): Observable<consultationinternal.ListPatientMediaFilesResponse> {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.ListPatientMediaFiles(params)));
  }

  public DeletePatientMedia(docID: string) {
    return this.clientService.client.pipe(switchMap(client => client.consultationinternal.DeletePatientMediaFile(docID)));
  }
}
