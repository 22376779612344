import { Injectable, signal } from '@angular/core';

export type LanguageOption = {
  name: string;
  code: string;
  flag: string;
};

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private availableLanguages = signal<Array<LanguageOption>>(
    [
      {
        name: 'English',
        code: 'en',
        flag: '🇬🇧'
      },
      {
        name: 'Français',
        code: 'fr',
        flag: '🇫🇷'
      },  
      {
        name: 'Deutsch',
        code: 'de',
        flag: '🇩🇪'
      }
    ]
  ).asReadonly();

  get languages() {
    return this.availableLanguages();
  }

  get $languages() {
    return this.availableLanguages;
  }

  getLanguageName(code: string) {
    return this.availableLanguages().find(language => language.code === code)?.name || '';
  }
}
