import { Injectable } from '@angular/core';
import { getAuth } from "firebase/auth";
import { environment } from "../../../environments/environment";
import Client, { ClientOptions, Local } from "./client";
import { catchError, from, Observable, of, switchMap, throwError } from "rxjs";
import { LoggerService } from '../logger/logger.service';
import { ClientErrorMessage } from './clienterror.interface';
import { RegionConfig } from 'src/environments/evironment.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private logger: LoggerService) { }


  async generateClient() {
    return await this.getRequestClient();
  }

  get client() {
    return from(this.generateClient());
  }

  request<T>(endpoint: Promise<T>): Observable<T> {
    return from(endpoint).pipe(
      catchError((err: ClientErrorMessage) => {
        this.logger.logClientError(err);
        return throwError(() => err);
      })
    );
  }

  requestWithClient<T>(endpoint: (client: Client) => Promise<T>): Observable<T> {
    return from(this.generateClient()).pipe(
      switchMap(client => from(endpoint(client)).pipe(
        catchError((err: ClientErrorMessage) => {
          // This is a hack to allow for empty responses to be returned such as unexpected end of JSON input 
          if (err instanceof SyntaxError ||  (err.code !== undefined && err.code.toString() === '12')) {
            return of(null as any); // return an Observable of null
          } else {
            this.logger.logClientError(err);
            return throwError(() => err);
          }
        })
      ))
    );
  }

  private getFirebaseAuth() {
    // Cannot get coverage due being unable to spyOn exported function. Look into better abstractions.
    return getAuth();
  }

  // Function to store region in localStorage
  setRegion(region: string) {
    localStorage.setItem('region', region);
  }

  // Function to get region from localStorage
  getRegion(): string | null {
    let storedRegion = localStorage.getItem('region');
    let exists = false;
    if (storedRegion) {
      for (const config of environment.regions) {
        if (config.code === storedRegion) {
          exists = true;
        }
      }
    }
    if (!exists) {
      this.removeRegion();
      return environment.defaultregion;
    }else{
      return storedRegion;
    }
  }

  // Function to remove region from localStorage
  removeRegion() {
    localStorage.removeItem('region');
  }

  getConfigForRegion():RegionConfig {
    const region = this.getRegion();
    const config = environment.regions.find(config => config.code === region)
    if(!config)
      throw new Error("No region config found for region " + region)
    return config;
  }  

  getRequestClient = async () => {
    const currentUser = this.getFirebaseAuth()?.currentUser;
    let token = "";
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult(false);
      if (idTokenResult) {
        token = idTokenResult.token;
      }
    }
    const regionConfig = this.getConfigForRegion();
    const language = localStorage.getItem('lang') || 'en';
    const env = regionConfig.api != "" ? regionConfig.api : Local;
    const clientOptions: ClientOptions = {
      requestInit: {  
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Accept-Language": language,
          'x-platform': 'web',
          'x-platform-version': navigator.userAgent,
          'x-client-version': environment.VERSION
        },
      },
    };
    return new Client(env, clientOptions);
  }
}
