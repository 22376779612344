import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { PrescribingEligibilityPillComponent } from "../prescribing-eligibility-pill/prescribing-eligibility-pill.component";
import { InputComponent } from '../forms/input/input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-details-display',
  templateUrl: './user-details-display.component.html',
  styleUrl: './user-details-display.component.scss',
  standalone: true,
  imports: [PrescribingEligibilityPillComponent, InputComponent, ReactiveFormsModule],
})
export class UserDetailsDisplayComponent {

  userDetails = input<any>();
  @Input() timezone?: string;
  @Input() activeConsultation?: boolean = false;
  @Output() newPostcode: EventEmitter<string> = new EventEmitter<string>();

  postcodeForm: FormGroup = new FormGroup({
    postcode: new FormControl('', Validators.required)
  })

  emitPostcode() {
    if(this.postcodeForm.invalid) return;
    this.newPostcode.emit(this.postcodeForm.get('postcode')?.value);
    this.postcodeForm.get('postcode')?.reset();
    this.postcodeForm.markAsUntouched();
  }
}
