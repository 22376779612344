import { Component, ElementRef, EventEmitter, inject, input, OnInit, Output, signal, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';
import Editor from '@toast-ui/editor';
import { isString } from 'lodash';
import { NgClass } from '@angular/common';
import { RichTextUtilsService } from '../services/rich-text-utils.service';

@Component({
  selector: 'app-rich-text-fullscreen-preview',
  standalone: true,
  imports: [NgClass],
  providers: [
    RichTextUtilsService
  ],
  templateUrl: './rich-text-fullscreen-preview.component.html',
  styleUrl: './rich-text-fullscreen-preview.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RichTextFullscreenPreviewComponent implements OnInit {
  // Services
  utils = inject(RichTextUtilsService);
  darkModeService: DarkModeService = inject(DarkModeService)
  
  initalValue = input<string>('');
  value = signal<string>('');
  showToolbar = input<boolean>(true);
  initialEditType = input<'markdown' | 'wysiwyg'>('wysiwyg');
  previewStyle = input<'vertical' | 'tab'>('tab');
  height = input<string>('100%');
  outputType = input<'markdown' | 'html'>('markdown');
  styleClass = input<string>('');

  private editor!: Editor;
  focused = false;


  initalised = false;
  @ViewChild('tuiEditor', { static: true }) editorElement!: ElementRef;
  @ViewChild('toolbarContainer', { read: ViewContainerRef, static: true })
  toolbarContainer!: ViewContainerRef; // To dynamically inject icons

  @Output() onChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.value.set(this.initalValue());
    this.editor = this.utils.createNewEditor({
      el: this.editorElement?.nativeElement,
      initialValue: this.initalValue(),
      initialEditType: this.initialEditType(),
      theme: this.darkModeService.darkModeOn() ? 'dark' : 'light',
      toolbarItems: this.getToolbarItems(this.showToolbar()),
      height: this.height(),
      events: this.getEditorEventHandler(),
      autofocus: false,
    })
    this.editor?.blur();
  }

  getEditorEventHandler() {
    return {
      load: () => {
        this.initalised = true;
      },
      change: ($event: any) => {
        this.onEditorValueChange();
      },
      focus: () => {
        this.focused = true;
      },
      blur: () => {
        this.focused = false;
      },
    } as any
  }

  getToolbarItems(showToolbar: boolean) {
    return showToolbar ? [
      [ 'heading', 'bold', 'italic',
        'ul','ol',
        'strike',
        'link'
      ],
    ] : []
  }

  onEditorValueChange() {
    const value = this.utils.getEditorValue(this.editor, this.outputType());
    if (value !== this.value() && isString(value)) {
      this.value.set(value);
      this.onChange.emit(value);
    }
  }

}
