import {
  APP_INITIALIZER,
  ErrorHandler,
  InjectionToken,
  NgModule,
} from '@angular/core';
import { LoginComponent } from './components/authentication/login/login.component';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics'
import { environment } from 'src/environments/environment';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslationComponent } from './components/translation/translation.component';
import { CustomTranslateLoader, HttpLoaderFactory } from './translate/custom.translate.loader';
import { InputComponent } from './components/forms/input/input.component';
import { InputGroupComponent } from './components/forms/inputgroup/inputgroup.component';
import { TitleComponent } from './components/design/title/title.component';
import { RegisterComponent } from './components/authentication/register/register.component';
import { RightImagePageWrapperComponent } from './components/design/rightimagepagewrapper/rightimagepagewrapper.component';
import { BannerComponent } from './components/design/banner/banner.component';
import { ForgottenPasswordComponent } from './components/authentication/forgottenpassword/forgottenpassword.component';
import { RightTextButton } from './components/design/righttextbutton/righttextbutton.component';
import { CenterTextButton } from './components/design/centertextbutton/centertextbutton.component';
import { SqaureImageGrid } from './components/design/squareimagegrid/squareimagegrid.component';
import { TenantsComponent } from './components/authentication/tenants/tenants.component';
import { JoiiHeaderComponent } from './components/design/joii-header/joii-header.component';
import { PageWrapperComponent } from './components/design/pagewrapper/pagewrapper.component';
import { JoiiSubHeaderComponent } from './components/design/joii-sub-header/joii-sub-header.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { CheckStaffAccessComponent } from './components/authentication/checkstaffaccess/checkstaffaccess.component';
import { VetAIHeaderComponent } from './components/design/vetai-header/vetai-header.component';
import { FormioFormComponent } from './components/formio_form/formio-form.component';
import { FormioAppConfig, FormioModule } from '@formio/angular';
import { StackedListComponent } from './components/design/stackedlist/stackedlist.component';
import { IsClientErrorMessagePipe } from './services/client/client.error.pipe';
import { ErrorComponent } from './components/design/error/error.component';
import { LoaderComponent } from './components/design/loader copy/loader.component';
import { DetailsListComponent } from './components/design/detailslist/detailslist.component';
import { SelectComponent } from './components/forms/select/select.component';
import { LabelComponent } from './components/forms/label/label.component';
import { ValidationComponent } from './components/forms/validation/validation.component';
import { HorizontalPillsComponent } from './components/design/horizontalpills/horizontalpills.component';
import { FormioSubmissionComponent } from './components/formio_submission/formio_submission.component';
import { FormSubmissionButtonComponent } from './components/forms/form-submission-button/form-submission-button.component';
import { TokboxVideoComponent } from './components/tokboxvideo/tokboxvideo.component';
import { NgIconsModule } from '@ng-icons/core';
import * as Sentry from '@sentry/angular-ivy';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { CheckboxComponent } from './components/forms/checkbox/checkbox.component';
import { SwitchRegionComponent } from './components/switchregion/switchregion.component';
import { TitleSidebarComponent } from './components/design/title-sidebar/title-sidebar.component';
import { DividerComponent } from './components/design/divider/divider.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import premium from '@formio/premium';
import tailwind from '@tsed/tailwind-formio';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { EmptyListComponent } from './components/empty_list/empty_list.component';
import { TextareaComponent } from './components/forms/textarea/textarea.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ExternalResourceManagerComponent } from './components/external-resource-manager/external-resource-manager.component';
import { VenomCodesPickerComponent } from './components/venom-codes-picker/venom-codes-picker.component';
import { TokboxTestComponent } from './components/tokbox_test/tokbox_test.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { YesNoComponent } from './components/yes-no/yes-no.component';
import { Formio } from 'formiojs';
import { CollapseListComponent } from './components/design/collapselist/collapselist.component';
import { ExpandableDivComponent } from './components/ExpandableDiv/expandable-div.component';
import { StaffOnlineViewComponent } from './components/staffonline/staffonlineview/staffonlineview.component';
import { StaffOnlineWriteComponent } from './components/staffonline/staffonlinewrite/staffonlinewrite.component';
import { CarouselComponent } from './components/design/carousel/carousel.component';
import { DateTimePickerComponent } from './components/datetime-picker/datetimepicker.component';
import { DropdownListComponent } from './components/design/dropdown-list/dropdown-list.component';
import { AppointmentCardComponent } from './components/appointment-card/appointment-card.component';
import { PetDetailsDisplayComponent } from './components/pet-details-display/pet-details-display.component';
import { UserDetailsDisplayComponent } from './components/user-details-display/user-details-display.component';
import { VenomDropdownComponent } from './components/venom-dropdown/venom-dropdown.component';
import { RecommendationsComponent } from './components/recommendations/recommendations.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogSimpleComponent } from './components/dialog-simple/dialog-simple.component';
import { LucideAngularModule } from 'lucide-angular';
import { WeightUnitSelectorComponent } from './components/weight-unit-selector/weightunitselector.component';
import { BinaryChoiceSelectorComponent } from './components/binary-choice-selector/binarychoiceselector.component';
import { PhoneInputComponent } from './components/forms/phone-input/phone-input.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BrazeService } from 'src/app/services/braze.service/braze.service';
import { JoiiFooterComponent } from './components/design/joii-footer/joii-footer.component';
import { JoiiModalComponent } from './components/design/joii-modal/joii-modal.component';
import { BrazeContentCardFeedComponent } from './components/braze/brazecontentcardsfeed/brazecontentcardfeed.component';
import { WeightSelectorComponent } from './components/weight-selector/weightselector.component';
import { MedicalHistoryComponent } from 'src/shared/components/medical-history/medical-history.component';
import { ResetpasswordLinkSentComponent } from './components/authentication/resetpassword-link-sent/resetpassword-link-sent.component';
import { SearchableSelectComponent } from './components/searchable-select/searchableselect.component';
import { ClickOutside } from 'ngxtension/click-outside';
import { ApplyPipe, CallPipe } from 'ngxtension/call-apply';
import { OwnerObservationsComponent } from './components/owner-observations/owner-observations.component';
import { JoiiPickerComponent } from './components/design/joii-picker/joii-picker.component';
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';
import { ImageCorouselModalComponent } from './components/design/image-carousel-modal/image-corousel-modal.component';
import { ProgressBarComponent } from './components/design/progress-bar/progress-bar.component';
import { PetSelectionComponent } from './components/design/pet-selection/pet-selection.component';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { DragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { DragAndDropListComponent } from './components/design/drag-and-drop-list/drag-and-drop-list.component';
import { CollapseContentComponent } from './components/design/collapsecontent/collapsecontent.component';
import { JoiiOfflineCheckComponent } from './components/design/joii-offline-check/joii-offline-check.component';
import { FileExtensionPipe } from './pipes/file-extension/file-extension.pipe';
import { RichTextComponent } from './components/forms/rich-text/rich-text.component';
import { RichTextViewerComponent } from './components/forms/rich-text/rich-text-viewer/rich-text-viewer.component';
import { HasErrorsPipe } from './pipes/has-error/has-error.pipe';
import { NotAuthorisedComponent } from './components/notauthorised/notauthorised.component';
import { MinutesToHoursPipe } from './pipes/minutes-to-hours/minutes-to-hours.pipe';
import { StaffNamePipe } from './pipes/staff-name/staff-name.pipe';
import { NGICONS } from 'src/icons';
import { HealthCheckDataComponent } from './components/health-check-data/health-check-data.component';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';

Formio.use(tailwind);
Formio.use(premium);
(Formio as any).icons = 'ionicons';

@NgModule({
  declarations: [],
  exports: [
    ReactiveFormsModule,
    JoiiHeaderComponent,
    SelectComponent,
    VetAIHeaderComponent,
    PageWrapperComponent,
    TitleComponent,
    DetailsListComponent,
    RightImagePageWrapperComponent,
    DividerComponent,
    JoiiSubHeaderComponent,
    TokboxVideoComponent,
    BannerComponent,
    IsClientErrorMessagePipe,
    ErrorComponent,
    HorizontalPillsComponent,
    LoaderComponent,
    StackedListComponent,
    TitleSidebarComponent,
    InputComponent,
    TextareaComponent,
    InputGroupComponent,
    RecommendationsComponent,
    TranslationComponent,
    TranslateModule,
    FormioFormComponent,
    CenterTextButton,
    ValidationComponent,
    FormioSubmissionComponent,
    FormSubmissionButtonComponent,
    CheckboxComponent,
    NgIconsModule,
    SidebarComponent,
    MultiSelectComponent,
    PaginationComponent,
    SearchBarComponent,
    EmptyListComponent,
    FileUploadComponent,
    ExternalResourceManagerComponent,
    VenomCodesPickerComponent,
    TokboxTestComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    YesNoComponent,
    CollapseListComponent,
    ExpandableDivComponent,
    AccordionComponent,
    StaffOnlineViewComponent,
    StaffOnlineWriteComponent,
    CarouselComponent,
    DropdownListComponent,
    AppointmentCardComponent,
    PetDetailsDisplayComponent,
    UserDetailsDisplayComponent,
    VenomDropdownComponent,
    WeightUnitSelectorComponent,
    BinaryChoiceSelectorComponent,
    PhoneInputComponent,
    JoiiFooterComponent,
    JoiiModalComponent,
    BrazeContentCardFeedComponent,
    WeightSelectorComponent,
    MedicalHistoryComponent,
    ResetpasswordLinkSentComponent,
    SearchableSelectComponent,
    ClickOutside,
    CallPipe,
    ApplyPipe,
    LabelComponent,
    OwnerObservationsComponent,
    HealthCheckDataComponent,
    JoiiPickerComponent,
    ProductSelectionComponent,
    ImageCorouselModalComponent,
    ProgressBarComponent,
    ScrollingModule,
    ExperimentalScrollingModule,
    DragAndDropListComponent,
    CollapseContentComponent,
    JoiiOfflineCheckComponent,
    FileExtensionPipe,
    RichTextComponent,
    RichTextViewerComponent,
    PetSelectionComponent,
    HasErrorsPipe,
    MinutesToHoursPipe,
    StaffNamePipe
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    // This ensures auth is ready to rock and roll before any component is created
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: Auth) => () => new Promise((resolve) => {
        auth.onAuthStateChanged((user) => {
          // Firebase is ready, regardless of user being logged in or not
          resolve(true);
        });
      }),
      deps: [Auth],
      multi: true,
    },
    {
      provide: FormioAppConfig,
      useValue: new FormioAppConfig({ baseUrl: environment.formIoRootUrl }),
    },
    BrazeService
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxFloatUiModule,
    AccordionComponent,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormioModule,
    ValidationComponent,
    FormSubmissionButtonComponent,
    DatePipe,
    DecimalPipe,
    CheckboxComponent,
    NgIconsModule,
    LucideAngularModule,
    ClickOutside,
    CallPipe,
    ApplyPipe,
    ScrollingModule,
    ExperimentalScrollingModule,
    DragDropModule,
    RichTextComponent,
    RichTextViewerComponent,
    PetSelectionComponent,
    DialogComponent,
    DialogSimpleComponent,
    TitleComponent,
    AppointmentCardComponent,
    CheckStaffAccessComponent,
    SelectComponent,
    MinutesToHoursPipe,
    StaffNamePipe,
    InputComponent,
    ForgottenPasswordComponent,
    LabelComponent,
    BannerComponent,
    RightImagePageWrapperComponent,
    PageWrapperComponent,
    ResetpasswordLinkSentComponent,
    NgIconsModule.withIcons(NGICONS),
    LoginComponent,
    RightTextButton,
    CenterTextButton,
    RegisterComponent,
    TenantsComponent,
    SqaureImageGrid,
    BinaryChoiceSelectorComponent,
    BrazeContentCardFeedComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    CarouselComponent,
    CollapseContentComponent,
    DetailsListComponent,
    CollapseListComponent,
    DividerComponent,
    DragAndDropListComponent,
    DropdownListComponent,
    ErrorComponent,
    HorizontalPillsComponent,
    ImageCorouselModalComponent,
    FileExtensionPipe,
    JoiiFooterComponent,
    JoiiHeaderComponent,
    ProgressBarComponent,
    JoiiModalComponent,
    JoiiOfflineCheckComponent,
    JoiiPickerComponent,
    JoiiSubHeaderComponent,
    LoaderComponent,
    StackedListComponent,
    TitleSidebarComponent,
    VetAIHeaderComponent,
    SwitchRegionComponent,
    EmptyListComponent,
    ExpandableDivComponent,
    FileUploadComponent,
    ExternalResourceManagerComponent,
    FormioFormComponent,
    FormioSubmissionComponent,
    InputGroupComponent,
    PhoneInputComponent,
    TextareaComponent,
    MedicalHistoryComponent,
    MultiSelectComponent,
    NotAuthorisedComponent,
    NotFoundComponent,
    OwnerObservationsComponent,
    HealthCheckDataComponent,
    PaginationComponent,
    ProductSelectionComponent,
    RecommendationsComponent,
    IsClientErrorMessagePipe,
    SearchBarComponent,
    PetDetailsDisplayComponent,
    SearchableSelectComponent,
    SidebarComponent,
    StaffOnlineViewComponent,
    StaffOnlineWriteComponent,
    TokboxTestComponent,
    TokboxVideoComponent,
    TranslationComponent,
    UserDetailsDisplayComponent,
    VenomCodesPickerComponent,
    VenomDropdownComponent,
    WeightSelectorComponent,
    YesNoComponent,
    WeightUnitSelectorComponent,
    HasErrorsPipe,
  ]
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    if (browserLang && browserLang.match(/en|de|fr/)) {
      translate.use(browserLang);
    } else {
      translate.use('en');
    }
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      translate.use(savedLang);
    }
  }
}

export const WINDOW = new InjectionToken<Window>('Global window object', {
  factory: () => window,
});
