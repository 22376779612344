@if(userDetails()){
<section class="w-full">
    <article class="w-1/2 inline-block align-top">
        <p><strong>Name:</strong> {{userDetails().first_name}} {{userDetails().last_name}}</p>
        <p><strong>Email:</strong> {{userDetails().email}}</p>
        <p><strong>Phone No:</strong> {{userDetails().phone_number}}</p>
        <p><strong>Postcode:</strong> {{userDetails().post_code}}</p>
        @if (timezone) {
        <p><strong>Timezone:</strong> {{timezone}}</p>
        }
        <app-prescribing-eligibility-pill [postcode]="userDetails().post_code"></app-prescribing-eligibility-pill>
        @if (activeConsultation) {
        <form [formGroup]="postcodeForm">
          <p class="mb-2"><strong>Change users postcode: </strong></p>
          <app-input name="postcode" formControlName="postcode" [span]="6" placeholder="Enter new postcode..."></app-input>
          <button class="joii-btn-primary mt-4" (click)="emitPostcode()">Update</button>
        </form>
        }
    </article>
</section>
}

