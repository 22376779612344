import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientErrorMessage } from 'src/shared/services/client/clienterror.interface';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  standalone: true,
  imports: [NgIf]
})

export class ErrorComponent {

  @Input() error!: ClientErrorMessage | undefined;
  @Output() retry = new EventEmitter<void>();

  mapErrorCode() {
    if (this.error?.code == 'not_found') {
      return '404';
    } else {
      return 'oops'
    }
  }

  retryClicked() {
    this.retry.emit();
  }
}
