import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class MixpanelService {
    private mixpanelToken: string;

    /**
     * constructor
     * get mixpanel token and initialize
     */
    constructor() {
        this.mixpanelToken = environment.mixpanel_token;

        if (environment.mixPanel_enabled) {
            this.init();
        }
    }

    /**
     * Initialize mixpanel.
     */
    init(): void {
        mixpanel.init(this.mixpanelToken, {
            track_pageview: "url-with-path"
        });
    }


    /**
      * Create new Alias for user
      * Call this method only once in user lifecycle
      *
      * @param {string} alias
      */
    createAlias(alias: string) {
        mixpanel.alias(alias, mixpanel.get_distinct_id());
    }

    /**
     * Identify User
     *
     * @param {string} alias
     */
    identify(alias: string) {
        mixpanel.identify(alias);
    }

    /**
     * Push new action to mixpanel.
     *
     * @param {string} id Name of the action to track.
     * @param {*} [action={}] Actions object with custom properties.
     * @memberof MixpanelService
     */
    track(id: string, action: any = {}): void {
        mixpanel.track(id, action);
    }

    /**
     * setup mixpannel
     *
     */
    setup() {
        // mixpanel.loggingEnabled = false;
        if (!environment.mixPanel_enabled) return;
        this.setSuperProperties({ Platform: 'web' });
    }

    /**
      * setPeople
      * Store user profiles in Mixpanel's People Analytics product
      * @param {Object} properties
      */
    setPeople(properties: any = {}): void {
        mixpanel.people.set(properties);
    }

    /**
     * setSuperProperties
     *
     * @param {object} properties
     */
    setSuperProperties(properties: mixpanel.Dict) {
        mixpanel.register(properties);
    }

    /**
     * sendEvent
     *
     * @param {string} event
     * @param {object} properties
     */
    sendEvent(event: string, properties?: mixpanel.Dict) {
        if (!environment.mixPanel_enabled) return;
        if (properties) {
            mixpanel.track(event, properties);
        } else {
            this.trackEvent(event);
        }
    }

    /**
     * trackEvent
     * @param {string} event
     */
    trackEvent(event: string) {
        if (!environment.mixPanel_enabled) return;
        mixpanel.track(event);
    }

    /**
     * trackExperiment
     * @description Track AB test experiement. This method should be called when the experiment is started to track which variant is being shown to the user.
     * @param {string} experimentName
     * @param {string} variantName
     */
    trackExperiment(experimentName: string, variantName: string) {
        mixpanel.track('$experiment_started', {
            experimentName,
            variantName
        });
    }

    /**
     * Reset Mixpanel
     */
    logout() {
        mixpanel.reset();
    }

}