import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { BrazeService } from "src/app/services/braze.service/braze.service";

@Component({
  selector: 'app-brazecontentcardfeed',
  templateUrl: './brazecontentcardfeed.component.html',
  standalone: true,
  imports: []
})
export class BrazeContentCardFeedComponent {
  @ViewChild('contentCards') contentCards!: ElementRef;

  constructor(
    private brazeService: BrazeService) { }

  @Input() brazeKey?: string;
  @Input() petId?: string;

  ngAfterViewInit() {

    if (this.contentCards.nativeElement) {
      this.brazeService.showContentCards(this.contentCards.nativeElement, this.brazeKey, this.petId);
    }
  }
}