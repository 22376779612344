<div class="w-full flex flex-row h-full">
  @if(show){
  
    <div class="w-20 h-screen fixed joii-bg-primary-light overflow-y-auto overflow-x-hidden">
    @if(darkModeService.darkModeOn()){
    <img
      src="https://firebasestorage.googleapis.com/v0/b/core-6p22-ukprod-658c405b.appspot.com/o/Logo-White.svg?alt=media&token=7fd21a01-92cd-4f5b-961f-b8cac716c0c7"
      alt="JoiiHome Logo"
      class="w-10 left-[20px] top-[18px] absolute flex-col justify-start items-center gap-0.5 inline-flex" />
    }
    @else(){
    <img
      src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FJoii_logo.svg?alt=media&token=9046e258-a5ca-49a3-a22c-5fb78e920d15"
      alt="JoiiHome Logo"
      class="w-10 left-[20px] top-[18px] absolute flex-col justify-start items-center gap-0.5 inline-flex" />
    }
    <nav class="flex flex-1 flex-col w-20">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        @if (config?.showLanguageSwitcher) {<app-language-dropdown class="block relative top-[73px] mx-auto"
          [compact]="true" [(ngModel)]="languageCode" (change)="onLanguageChange($event)" />}
        @for(item of config.items; track $index){
        <li *ngIf="hasAccess(item)">
          <a (click)="menuItemClicked(item)" [attr.data-automationid]="item.dataautomationid"
            class=" cursor-pointer w-10 h-[58px] left-[20px] top-[73px] relative flex-col justify-start items-center gap-0.5 inline-flex">
            <div class="justify-start items-start inline-flex">
              <div class="w-10 h-10 rounded-lg justify-center items-center flex"
                [ngClass]="{'joii-bg-primary text-white dark:text-slate-800': currentPath.startsWith(item.id),'joii-bg-primary-light joii-text-primary': !currentPath.startsWith(item.id)}">
                <ng-icon [name]="item.icon"></ng-icon>
              </div>
            </div>
            <div
              class="text-slate-800 dark:text-slate-100 text-xs font-medium font-['Inter'] w-16 break-all text-center tracking-tight truncate">
              {{item.title}}
            </div>
          </a>
        </li>
        }
      </ul>
    </nav>
  </div>

  <main class="w-[calc(100%-80px)] h-full ml-20">

    <div class="w-full h-full">
      @if (pagewrapper) {
      <app-pagewrapper [setWhitebackground]="setWhitebackground" class="overflow-auto flex flex-col">
        <div class="flex flex-row-reverse" *ngIf="showSubMenu">
          <div
            class=" w-2/10 text-sm font-semibold leading-6 text-gray-900 top-0 object-right-top flex flex-row justify-evenly">
            <a class="cursor-pointer" (click)="accountclicked()" data-automationid="sidebar-open-profile">
              <span class="sr-only">Your profile</span>
              <img class="h-8 w-8 rounded-full bg-gray-50" [src]="profilephoto" alt="">
            </a>
            <app-switchregion></app-switchregion>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </app-pagewrapper>
      } @else {
      <main class="w-full h-full">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </main>
      }
    </div>
  </main>
  } @else {
  <main class="w-full h-full">
    <div class="w-full h-full">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </div>
  </main>
  }
</div>

<ng-template #contentTpl><ng-content></ng-content></ng-template>