import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, of } from 'rxjs';
import { CookieService } from '../services/cookies/cookie_service';
import { AuthenticationService } from '../services/auth/auth_service';

/**
 * Redirects to the login page if the user is not logged in.
 * @param route 
 * @param state 
 * @returns boolean
 */
export const OnboardingGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const cookieService = inject(CookieService);
  const authService = inject(AuthenticationService);
  const tenants = authService.getAllTenants();

  return of(cookieService.getCookie("loggedIn")).pipe(
    map((loggedIn) => {
      if (!loggedIn) {
        const url = tenants?.length > 1 ? '/tenants/login' : '/login';
        router.navigate([url], {
          queryParams: { redirectTo: `${state.url}` }
        });
        return false;
      }
      return true;
    }
  ));
};
