import { Injectable, ElementRef, inject, Renderer2, ComponentFactoryResolver, ViewContainerRef, OnDestroy } from '@angular/core';
import Editor from '@toast-ui/editor';
import { isString, replace, trim } from 'lodash';
import { LucideAngularComponent } from 'lucide-angular';

type EditorConfig = {
  el: HTMLElement;
  initialValue: string;
  initialEditType: 'markdown' | 'wysiwyg';
  theme: 'dark' | 'light';
  toolbarItems: any[];
  height: string;
  events: any;
  autofocus: boolean;
}

@Injectable()
export class RichTextUtilsService  {
  renderer = inject(Renderer2);
  factory = inject(ComponentFactoryResolver);
  events: any[] = [];

  createNewEditor(config: EditorConfig): Editor {
    return new Editor({
      el: config.el,
      initialValue: config.initialValue,
      initialEditType: config.initialEditType,
      theme: config.theme,
      toolbarItems: config.toolbarItems,
      height: config.height,
      events: config.events,
      autofocus: config.autofocus,
    });
  }

  getEditorHTML(editor: Editor): string {
    return editor?.getHTML() || '';
  }

  getEditorMarkdown(editor: Editor): string {
    return editor?.getMarkdown() || '';
  }

  getEditorValue(editor: Editor, outputType: 'markdown' | 'html'): string {
    return outputType === 'html' ? editor?.getHTML() || '' : editor?.getMarkdown() || '';
  }

  setEditorValue(editor: Editor, value: string, focused: boolean) {
    if (!isString(value)) return;

    this.isHTML(value) ? editor?.setHTML(value, focused) : editor?.setMarkdown(value, focused);
  }

  isHTML(str: string): boolean {
    const htmlRegex = /<[^>]+>/g;
    return htmlRegex.test(str);
  }

  getPlainText(editor: Editor, editorElement: ElementRef): string {
    if (!editor || !editorElement || !editorElement?.nativeElement) return '';

    try {
      const htmlContent = editor?.getHTML() || '';

      if (!htmlContent) return '';

      // Convert HTML to plain text using DOMParser
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      // Function to traverse DOM tree and extract text content with spaces
      const getTextWithSpaces = (node: Node): string => {
        let text = '';
        node.childNodes.forEach(childNode => {
          if (childNode.nodeType === Node.TEXT_NODE) {
            text += childNode.textContent;
          } else {
            text += getTextWithSpaces(childNode);
            // Add space after each element (except for the last one)
            if (childNode.nextSibling) {
              text += ' ';
            }
          }
        });
        return text;
      };

      const plainText = getTextWithSpaces(doc.body);
      const plainTextTrimmed = trim(replace(plainText, /\s+/g, ' '));
      return plainTextTrimmed;
    } catch (error) {
      console.log('Error getting plain text', error);
      return '';
    }
  }

  getCustomToolbarIcon(icon: string, container: ViewContainerRef, eventHandler: () => void) {
    const button = this.renderer.createElement('button');
    
    // Style the button
    this.renderer.setAttribute(button, 'class', `custom-${icon}-icon`);
    this.renderer.setStyle(button, 'display', 'flex');
    this.renderer.setStyle(button, 'align-items', 'center');
    this.renderer.setStyle(button, 'margin', '0px');
    this.renderer.setStyle(button, 'color', '#555555');
    this.renderer.setAttribute(button, 'type', 'button');

    // Add lucide icon
    if (button) {
      button.innerHTML = ''; // Clear inner content
    }

    const componentFactory = this.factory.resolveComponentFactory(LucideAngularComponent);
    const componentRef = container.createComponent(componentFactory);

    // Set the icon component properties
    componentRef.setInput('name', 'fullscreen');
    componentRef.setInput('strokeWidth', 2);
    componentRef.setInput('size', '25');  
    componentRef.changeDetectorRef.detectChanges();

    if (button) {
      button.appendChild(componentRef.location.nativeElement);
    }
    let listener: any;

    // Listen for the click event
    listener = this.renderer.listen(button, 'click', () => {
      eventHandler();
    });

    return button
  }

}
