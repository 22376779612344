import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays, formatDistanceStrict, format } from 'date-fns';

/**
 * Returns the duration between 2 dates in a human-readable format.
 */
@Pipe({
  name: 'humanizeTime',
  standalone: true,
  pure: true
})
export class HumanizeTimePipe implements PipeTransform {

  transform(compareTime: string | Date, now: string | Date): unknown {
    return this.humanizeTime(compareTime, now);
  }

  private humanizeTime(time: string | Date, now: string | Date): string {
    const timeDate = new Date(time);
    const nowDate = new Date(now);

    const daysDifference = differenceInDays(nowDate, timeDate);

    if (daysDifference === 0) {
      // Same day, shorten the output of formatDistanceStrict
      const distance = formatDistanceStrict(timeDate, nowDate, { addSuffix: true });

      // Replace long units with shorter versions
      return distance
        .replace('minute', 'min')
        .replace('second', 'sec')
        .replace('hour', 'hr')
        .replace('minutes', 'mins')
        .replace('seconds', 'secs')
        .replace('hours', 'hrs');

    } else if (daysDifference === 1) {
      // Exactly 1 day ago
      return 'Yesterday';
    } else if (daysDifference < 365) {
      // More than 1 day ago, but within the same year
      return format(timeDate, 'd MMM');
    } else {
      // More than a year ago
      return format(timeDate, 'd MMM yy');
    }
  }

}
