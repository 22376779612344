import { Injectable, inject, signal } from '@angular/core';
import { VenomCodesDataService } from './venom-codes-data.service';
import { venomcodeinternal } from '../client/client';
import { BehaviorSubject, Observable, Subject, catchError, finalize, map, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VenomCodesService {
  private dataService = inject(VenomCodesDataService); // This is the data service that will be used to fetch the data
  private cachedVenomCodes = new BehaviorSubject<venomcodeinternal.AllVenomCodesResponse | undefined>(undefined); // This is the cached venom codes
  private cacheInitialized = signal<boolean>(false); // This is a signal that will be used to indicate that the cache has been initialized
  private fetchingData = signal<boolean>(false); // This is a signal that will be used to indicate that the data is being fetched
  private requestQueue: Subject<boolean>[] = []; // This is a queue that will be used to store the requests that are waiting to be processed


  get loading() {
    return this.fetchingData;
  }

  get initialized() {
    return this.cacheInitialized();
  }

  get totalVenomCategories() {
    return this.cachedVenomCodes.value?.categories?.length || 0;
  }

  getListVenomCodes() {
    if (!this.cacheInitialized()) return this.fetchVenomCodes();

    return this.cachedVenomCodes.asObservable().pipe(
      switchMap(venomCodes => {
        if (!venomCodes) {
          return this.fetchVenomCodes();
        }
        return this.cachedData();
      })
    );
  }

  private fetchVenomCodes() {
    if (!this.cacheInitialized() && !this.fetchingData()) {
      this.fetchingData.set(true);
      return this.dataService.getListVenomCodes().pipe(
        map((response) => {
          return this.cloneVenomCode(response);
        }),
        tap(response => {
          this.cachedVenomCodes.next(this.cloneVenomCode(response));
          this.cacheInitialized.set(true);
          this.processRequestQueue();
        }),
        catchError(error => {
          console.error('Error fetching venom codes:', error);
          this.cachedVenomCodes.next(undefined);
          this.cacheInitialized.set(false);
          this.processRequestQueue();
          return of(undefined);
        }),
        finalize(() => this.fetchingData.set(false))
      );
    } else {
        if (this.cacheInitialized() && !this.fetchingData()) {
          return this.cachedData();
        }
         // If another request is in progress, return the existing observable
         const newRequest = new Subject<boolean>();
         this.requestQueue.push(newRequest);
         return newRequest.asObservable().pipe(
           switchMap(() => this.cachedData())
         );
    }
  }

  private processRequestQueue(): void {
    while (this.requestQueue.length > 0) {
      const request = this.requestQueue.shift();
      if (request) {
        request.next(true);
      }
    }
  }

  private cachedData(): Observable<venomcodeinternal.AllVenomCodesResponse | undefined> {
    return this.cachedVenomCodes.asObservable().pipe(
      map(venomCodes => {
        return this.cloneVenomCode(venomCodes);
      })
    );
  }

  private cloneVenomCode(response: venomcodeinternal.AllVenomCodesResponse | undefined) {
    // All string data so this method is highly performant.
    return JSON.parse(JSON.stringify(response));
  }

  clearCache() {
    this.cachedVenomCodes.next(undefined);
    this.cacheInitialized.set(false);
  }

}
