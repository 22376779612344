import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation, forwardRef, inject, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import Editor from '@toast-ui/editor';
import { isString } from 'lodash';
import { LucideAngularModule } from 'lucide-angular';
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';
import { RichTextFullscreenPreviewComponent } from './rich-text-fullscreen-preview/rich-text-fullscreen-preview.component';
import { RichTextUtilsService } from './services/rich-text-utils.service';

@Component({
  selector: 'app-rich-text',
  standalone: true,
  imports: [CommonModule, FormsModule, LucideAngularModule, RichTextFullscreenPreviewComponent],
  templateUrl: './rich-text.component.html',
  styleUrl: './rich-text.component.scss',
  providers: [
    {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RichTextComponent),
    multi: true
    },
    RichTextUtilsService,
  ],
  encapsulation: ViewEncapsulation.None
})
export class RichTextComponent implements ControlValueAccessor, OnInit, OnDestroy {
  // Services
  utils = inject(RichTextUtilsService);
  dialog = inject(Dialog);
  darkModeService: DarkModeService = inject(DarkModeService)

  @Input() id: string = '';
  @Input() initialEditType: 'markdown' | 'wysiwyg' = 'wysiwyg';
  @Input() previewStyle: 'vertical' | 'tab' = 'tab';
  @Input() height!: string;
  @Input() value!: string;
  @Input() outputType: 'markdown' | 'html' = 'markdown';
  @Input() showToolbar: boolean = true;
  @Input() styleClass: string = '';
  @Input() enableFullscreen = true;
  @Output() touched = new EventEmitter<void>();
  updatingEditor = signal<boolean>(false);
  editor!: Editor;
  focused = false;

  initalised = false;
  @ViewChild('tuiEditor', { static: true }) editorElement!: ElementRef;
  @ViewChild('toolbarContainer', { read: ViewContainerRef, static: true }) toolbarContainer!: ViewContainerRef; // To dynamically inject icons
  @ViewChild('fullscreen', { static: true }) fullscreenDialog!: TemplateRef<any>;

  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  ngOnInit() {
    this.editor = this.utils.createNewEditor({
      el: this.editorElement?.nativeElement,
      initialValue: this.value,
      initialEditType: this.initialEditType,
      theme: this.darkModeService.darkModeOn() ? 'dark' : 'light',
      toolbarItems: this.getToolbarItems(this.showToolbar, this.enableFullscreen),
      height: this.height,
      events: this.getEditorEventHandler(),
      autofocus: false,
    })
    this.editor?.blur();
  }


  onEditorValueChange() {
    const value = this.utils.getEditorValue(this.editor, this.outputType);
    if (value !== this.value && isString(value) && this.initalised) {
      this.writeValue(value, true);
      this.updateChanges();
    }
  }

  updateChanges(): void {
    this.onChange(this.value);
  }

  writeValue(value: string, skipEditor = false): void {
    if (this.value !== value && !skipEditor) {
      this.updatingEditor.set(true);
      this.utils.setEditorValue(this.editor, value, this.focused);
    };
    this.value = value;
    this.updatingEditor.set(false);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getPlainText(): string {
    // Get the HTML content from the ElementRef
    return this.utils.getPlainText(this.editor, this.editorElement);
  }

  getToolbarItems(showToolbar: boolean, showFullscreen: boolean) {
    return showToolbar ? [
      [ 'heading', 'bold', 'italic',
        'ul','ol',
        'strike',
        'link',
        showFullscreen ? {
          name: 'fullscreen',
          tooltip: 'Fullscreen',
          el: this.utils.getCustomToolbarIcon('fullscreen', this.toolbarContainer, () => this.openFullscreenPreview()),
        } : ''
      ],
    ] : [];
  }  

  getEditorEventHandler() {
    return {
      load: () => {
        this.initalised = true;
      },
      change: ($event: any) => {
        if (this.updatingEditor()) return;
        this.onEditorValueChange();
      },
      focus: () => {
        this.onTouched();
        this.touched.emit();
        this.focused = true;
      },
      blur: () => {
        this.onTouched();
        this.touched.emit();
        this.focused = false;
      }
    }
  }  

  openFullscreenPreview() {
    this.dialog.open(this.fullscreenDialog);
  }

  onPreviewChange(value: string) {
    this.writeValue(value, true);
    this.utils.isHTML(value) ? this.editor?.setHTML(value, this.focused) : this.editor?.setMarkdown(value, this.focused);
    this.updateChanges();
  }

  ngOnDestroy(): void {
    this.editor?.destroy();
  }
}
