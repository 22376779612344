import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedModule } from 'src/shared/shared.module';
import { SubmissionHistoryComponent } from './pages/submissionHistory/submission-history.component';
import { MoreDetailsComponent } from './components/more-details/more-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LucideAngularModule } from 'lucide-angular';
import { ICONS } from 'src/icons';
import { VideoChecksComponent } from './pages/videochecks/videochecks.component';
import { DarkModeToggleComponent } from 'src/shared/components/dark-mode-toggle/dark-mode-toggle.component';
import { ConsultationHistoryListComponent } from '../../shared/components/consultation-history-list/consultation-history-list.component';
import { AfiClinicalHistoryComponent } from "../consultations/components/afi-clinical-history/afi-clinical-history.component";
import { AppointmentChatCardComponent } from "../../shared/components/appointment-chat-card/appointment-chat-card.component";


@NgModule({
  declarations: [
    DashboardComponent,
    SubmissionHistoryComponent,
    MoreDetailsComponent,
    VideoChecksComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    LucideAngularModule,
    LucideAngularModule.pick(ICONS),
    DarkModeToggleComponent,
    ConsultationHistoryListComponent,
    AfiClinicalHistoryComponent,
    AppointmentChatCardComponent
]
})
export class DashboardModule { }
