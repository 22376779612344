import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';

@Pipe({
  name: 'fileExtension',
  standalone: true
})
export class FileExtensionPipe implements PipeTransform {

  transform(value: string, type: string): unknown {
    if (!isString(value)) return '';
    if (!isString(type)) return '';

    let fileType = '';
    let returnedValue = '';

    const url = new URL(value);
    const parts =  url.pathname.split('.');
    const fileExtension = (parts?.pop() || '')?.toLowerCase();

    switch (fileExtension) {
      default:
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
      case 'bmp':
      case 'webp':
      case 'heic':
      case 'tif':
      case 'tiff':
        fileType = 'image';
        break;
      case 'mp4':
      case 'webm':
        fileType = 'video';
        break;
    }
    switch (type) {
      case 'type':
        returnedValue = fileType;
        break;
      default:
      case 'extension':
        returnedValue = fileExtension;
        break;
    }
    return returnedValue;
  }

}
