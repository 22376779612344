import { inject, Injectable, signal, Signal } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader"
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, filter, from, map, of, shareReplay, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { PartnerPracticeService } from "../partner-practices.service/partner-practice.service";
import { entities } from "../client/client";

@Injectable({
    providedIn: 'root'
})
export class GooglePlacesService {
    private partnerPracticeService = inject(PartnerPracticeService)
    private firebaseLoader = new Loader({ apiKey: environment.firebase.apiKey, version: "weekly" });
    private geometry = from(this.firebaseLoader.importLibrary('geometry')).pipe(shareReplay());
    private placesService = from(this.firebaseLoader.importLibrary('places')).pipe(map(lib => new lib.PlacesService(document.createElement('div'))),shareReplay());
    private cachedLocationLookups = new BehaviorSubject<Map<string, entities.PartnerPractice | undefined>>(new Map()); // This is the cached venom codes
    private partnerPractices$ = this.partnerPracticeService.listPartnerPractices().pipe(
        map(x => x.practices),
        catchError(err => {
            console.error('Error fetching partner practices:', err);
            return of([]);
        }),
        shareReplay()
    );

    public GetVetsForAddress(address: string): Observable<searchResult> {
        return this.placesService.pipe(
            switchMap((places) =>
                new Observable<searchResult>(observer => {
                    places?.findPlaceFromQuery(
                        {
                            query: address,
                            fields: ['place_id', 'name', 'geometry', 'types'],
                        },
                        (resp, status) => {
                            if (status === 'OK') {
                                const filteredResults = resp?.filter(place => 
                                    place.types?.includes("veterinary_care")
                                ) || [];
                                observer.next({
                                    results: filteredResults,
                                    paging: null
                                });
                            } else {
                                observer.error(status);
                            }
                        }
                    );
                })
            )
        );
    }
    

    public getPartnerPracticeWithin25Miles(address: string): Observable<entities.PartnerPractice | undefined> {
        return combineLatest([of(address), this.cachedLocationLookups]).pipe(
            filter(([address]) => address != ""),
            switchMap(([address, cache]) => {
                if(cache.get(address)) {
                    return of(cache.get(address))
                }
                return this.partnerPractices$.pipe(
                    switchMap(pp => {
                        return this.findPlaceFromQuery({ query: address, fields: ['place_id', 'name', 'geometry', 'types'] }).pipe(
                            switchMap((result) => {
                                return this.getFirstPracticeWithin25Miles(result![0], pp!)
                            }),
                            map(practice => {
                                cache.set(address, practice);
                                return practice
                            }))
                    })
                )
            }),
            catchError((error) => {
                console.error('Error fetching partner practice:', error);
                return of(undefined);
            })
        );
    }

    private findPlaceFromQuery(query: google.maps.places.FindPlaceFromQueryRequest): Observable<google.maps.places.PlaceResult[] | null> {
        return this.placesService.pipe(
            switchMap((places) =>
                new Observable<google.maps.places.PlaceResult[] | null>(observer => {
                    places?.findPlaceFromQuery(query, (resp, status) => {
                        if (status === 'OK') {
                            observer.next(resp);
                        } else {
                            observer.error(status);
                        }
                    });
                })
            )
        );
    }

    private getFirstPracticeWithin25Miles(resp: google.maps.places.PlaceResult, partnerPractices: entities.PartnerPractice[]): Observable<entities.PartnerPractice | undefined> {
        return this.geometry.pipe(
            map((geometry) => {
                const twentyFiveMilesInMetres = 40233.6;
                return partnerPractices?.find(value => {                    
                    return geometry?.spherical?.computeDistanceBetween(resp.geometry!.location!, {
                        lat: Number.parseFloat(value.lat),
                        lng: Number.parseFloat(value.long)
                    }) <= twentyFiveMilesInMetres;
                });
            }),
            catchError(error => {
                console.error('Error fetching geometry:', error);
                return of(undefined);
            })
        );
    }
}

export interface searchResult {
    results: google.maps.places.PlaceResult[] | null,
    paging: google.maps.places.PlaceSearchPagination | null
}


