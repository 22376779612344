import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaffOnboardingComponent } from './staffonboarding/staffonboarding.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingGuard } from 'src/shared/guards/onboarding.guard';

const routes: Routes = [
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [OnboardingGuard],
    children: [
      {
        path: 'staff',
        component: StaffOnboardingComponent,
        title: 'Joii PMS - Staff Onboarding'
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
