import { Injectable, inject } from "@angular/core";
import { ClientService } from "../client/client.service";
import { Observable, switchMap, of } from "rxjs";
import { bookinginternal, usersubmission } from "../client/client";

@Injectable({
  providedIn: 'root'
})
export class UserSubmissionsService {
  clientService = inject(ClientService);

  public ListUserSubmissions(listUserSubmissionsRequest: usersubmission.ListUserSubmissionsRequest): Observable<usersubmission.ListUserSubmissionsResponse> {
    return this.clientService.client.pipe(switchMap(client => client.usersubmission.ListUserSubmission(listUserSubmissionsRequest)))
  }

  ListAppointmentsTypeformSubmissions(pet_doc_id: string): Observable<bookinginternal.TypeformSubmissionsResponse> {
    return this.clientService.client.pipe(switchMap(client => client.bookinginternal.ListAppointmentsTypeformSubmissions({PetDocID: pet_doc_id})))
  }
}