<div class="my-6">
  <div class="sm:hidden">
    <label for="tabs" class="text-xs text-gray-400 font-semibold mb-3">change tab</label>
    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
    <select (change)="menuItemChanged($event)" id="tabs" name="tabs"
      class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-teal-400 focus:outline-none sm:text-sm">
      <option *ngFor="let item of config.items" [value]="item.id">{{item.title}}</option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8 flex-wrap" aria-label="Tabs">
        @for (item of config.items; track item.id) {
          @if(item?.hidden ? !item?.hidden() : true) {
            <a (click)="menuItemClicked(item)"
            [ngClass]="{'border-blue-900 joii-text-primary': currentPath.startsWith(item.id),'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': !currentPath.startsWith(item.id)}"
            class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer">{{item.title}}</a>
          }
        }
      </nav>
    </div>
  </div>
</div>
