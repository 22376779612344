import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  Injector,
  input,
  Input,
  OnChanges,
  OnInit, Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor, Form, FormGroup, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule, ValidationErrors,
} from '@angular/forms';
import { get, isEqual } from "lodash";
import { LabelComponent } from '../label/label.component';
import { ValidationComponent } from '../validation/validation.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    }
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ValidationComponent, LabelComponent, NgClass, NgIf]
})

export class InputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() label!: string;
  @Input() name!: string;
  @Input() placeholder!: string;
  @Input() type: string = "text";
  @Input() readonly: boolean = false;
  @Input() span!: number;
  @Input() errormessage!: string;
  @Input() inputStyleClass!: string;
  @Input() hostStyleClass: string = '';
  @Input() automationID?: string = '';
  @Input() errorPositionAbsolute: boolean = false;
  @Input() defaultValue: string = '';
  @Input() ngModelOptions!: { name?: string | undefined; standalone?: boolean | undefined; };
  showOptional = input(false)

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() touched: EventEmitter<any> = new EventEmitter<any>();

  value!: string | null;
  errors!: ValidationErrors | null | undefined;
  disabled = false;

  @HostBinding('class') get t() {
    return `p-0 border-0 bg-transparent ${this.hostStyleClass.replace(/'/g, '')} sm:col-span-` + this.span;
  };

  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  formControl!: NgControl | null;

  constructor(
    private injector: Injector,
    @Optional() private formDirective: FormGroupDirective
  ) {
  }

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  get submitted() {
    return this.formDirective ? this.formDirective.submitted : false;
  }

  ngOnInit() {
    if (!this.value) {
      this.value = this.defaultValue;
      this.onChange(this.value);
    }
    this.formControl = this.ngControl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl && !isEqual(changes?.['value']?.previousValue, changes?.['value']?.currentValue)) {
      this.value = this.ngControl?.value;
      this.onChange(this.value);
      this.onTouched();
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onInputChange(newValue: string) {
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
      this.valueChange.emit(this.value ?? undefined);
    }
    this.onTouched();
  }

  onInputBlur(event: Event) {
    this.touched.emit();
    const newValue = (event.target as HTMLInputElement).value;
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
    }
    this.onTouched();
  }
}
