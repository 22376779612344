import { Params } from "@angular/router";
import { Signal, signal, WritableSignal } from "@angular/core";

export interface JoiiHeader {
    defaultHeaderPaths: JoiiDefaultPathItem[];
    logo: string;
    items: JoiiHeaderItem[];
}

export interface JoiiDefaultPathItem {
    path: string,
    progress: number, // percent value 
}

export interface JoiiHeaderItem {
    index: number;
    id: string;
    title: string;
    type: JoiiHeaderItemType;
    icon?: string;
    subItems?: JoiiHeaderItem[];
    queryParams?: Params,
    state: JoiiHeaderItemState;
    environments: JoiiHeaderEnvironments;
    hidden?: Signal<boolean>;
}

export enum JoiiHeaderEnvironments {
    All = 'all',
    NonLive = 'nonLive'
}

export enum JoiiHeaderItemType {
    Logout = 'logout',
    Register = 'register',
    Path = 'path',
    Callback = 'callback',
    TokenCopier = 'token-copier',
    Dropdown = 'dropdown',
    Link = 'link',
}
export enum JoiiHeaderItemState {
    DesktopOnly = 'desktop',
    MobileOnly = 'mobile',
    Both = 'both',
}