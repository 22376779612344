import { Injectable, inject } from '@angular/core';
import { entities } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
  providedIn: 'root'
})
export class ChatDataService {
  client = inject(ClientService);

  GetPendingChats(params: entities.GetPendingChatsRequest) {
    return this.client.requestWithClient(client => client.bookinginternal.GetPendingChats(params));
  }
  
  AddChatConsultation(params: entities.CreateChatConsultationRequest) {
    return this.client.requestWithClient(client => client.consultationinternal.AddChatConsultation(params));
  }

  AddChatParticipant(chatDocID: string, staffProfileID: string) {
    return this.client.requestWithClient(client => client.bookinginternal.AddChatParticipant(chatDocID, staffProfileID));
  }
}
