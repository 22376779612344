import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../services/auth/auth_service';
import { CookieService } from '../services/cookies/cookie_service';
import { catchError, of, map, switchMap } from 'rxjs';
import { AccountDataService } from 'src/app/services/account.data.service/account.data.service';
import { environment } from 'src/environments/environment';
import { StaffProfileDataService } from 'src/pms/services/staffprofiles.data.service/staffprofiles.data.service';

/**
 * Redirects to the login page if the user is not logged in.
 * Redirects to the onboarding page if the user is not onboarded.
 * @param route 
 * @param state 
 * @returns boolean
 */
export const LoginGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const cookieService = inject(CookieService);
  const accountDataService = inject(AccountDataService);
  const staffProfileDataService = inject(StaffProfileDataService);
  const router = inject(Router);

  const tenants = authService.getAllTenants();
  const currentUrl = state.url;
  const onboardingRedirectUrl = environment.onboarding_route;
  const app = environment.app;

  // Check if the user is onboarded on the App.
  const checkAppOnboarding = (currentUrl: string, redirectUrl: string) => {
    return of(currentUrl).pipe(
      switchMap((url) => {
        return accountDataService.GetMyUser().pipe(
          catchError((err) => (of(err))),
          map((response: any) => {
            if (response.code && response.code == 'failed_precondition') {
              router.navigate([redirectUrl], { queryParams: { redirectTo: `${currentUrl}` }, queryParamsHandling: 'merge' });
              return false;
            } else {
              return true;
            }
          },
          ));
      })
    );
  }

  // Check if the user is onboarded on the PMS.
  const checkPMSOnboarding = (currentUrl: string, redirectUrl: string) => {
    return of(currentUrl).pipe(
      switchMap((url) => {
        return staffProfileDataService.GetMyStaffProfile().pipe(
          catchError((err) => (of(err))),
          map((response: any) => {
            if (response?.code === "not_found") {
              router.navigate([redirectUrl], { queryParams: { redirectTo: `${currentUrl}` }, queryParamsHandling: 'merge' });
              return false;
            }
            return true;
          },
          ));
      })
    );
  }

  return of(cookieService.getCookie("loggedIn")).pipe(
    switchMap((loggedIn) => {
      if (!loggedIn) {
        const url = tenants?.length > 1 ? '/tenants/login' : '/login';
        router.navigate([url], {
          queryParams: { redirectTo: `${state.url}` }
        });
        return of(false);
      }

      authService.setSentryUser();

      switch (app) {
        case 'App': return checkAppOnboarding(currentUrl, onboardingRedirectUrl);
        case 'PMS': return checkPMSOnboarding(currentUrl, onboardingRedirectUrl);
        default: return of(true);
      }

    })
  );

}
