import { Component, OnInit } from '@angular/core';
import { validatePassword } from '@angular/fire/auth';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Tenant } from 'src/environments/evironment.interface';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { ValidationService } from 'src/shared/services/validation/validation.service';
import { TitleComponent } from '../../design/title/title.component';
import { BannerComponent } from '../../design/banner/banner.component';
import { InputComponent } from '../../forms/input/input.component';
import { TranslateModule } from '@ngx-translate/core';
import { RightTextButton } from '../../design/righttextbutton/righttextbutton.component';
import { FormSubmissionButtonComponent } from '../../forms/form-submission-button/form-submission-button.component';
import { CenterTextButton } from '../../design/centertextbutton/centertextbutton.component';
import { NgIconsModule } from '@ng-icons/core';
import { NgIf } from '@angular/common';
import { RightImagePageWrapperComponent } from '../../design/rightimagepagewrapper/rightimagepagewrapper.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormsModule, TitleComponent, BannerComponent, InputComponent, TranslateModule, RightTextButton, FormSubmissionButtonComponent, CenterTextButton, NgIconsModule, RightImagePageWrapperComponent]
})

export class LoginComponent {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) { }


  form = this.fb.group({
    email: ['', [ValidationService.validateEmail]],
    password: ['', [ValidationService.validateLoginPassword]]
  });
  loading!: boolean;
  error!: string;
  activeTenant: Tenant | undefined = this.authenticationService.getActiveTenant();
  providerswitcher: boolean = environment.providerswitcher;
  showPassword: boolean = false;

  onSubmit(form: FormGroup) {
    if (!form.valid) return;
    if (this.loading) return;

    this.error = "";
    this.loading = true;
    this.authenticationService.loginWithEmailAndPassword(form.value.email, form.value.password)
      .then(() => this.next())
      .catch(error => this.handlerError(error.message))
      .finally(() => this.resetForm());
  }

  register() {
    this.router.navigate(['/register'], { queryParamsHandling: 'preserve' });
  }

  forgottenpassword() {
    this.router.navigate(['/forgottenpassword'], { queryParamsHandling: 'preserve' });
  }

  anothertenant() {
    this.router.navigate(['/tenants/login'], { queryParamsHandling: 'preserve' });
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  next() {
    const url = this.route.snapshot.queryParamMap.get('redirectTo') ?? "";
    const decodedUrl = decodeURIComponent(url);
    if (decodedUrl) {
      this.redirectToUrl(decodedUrl)
    }
    else{
      this.router.navigate([environment.afterAuthPath]);
    }
  }

  handlerError(error: string) {
    this.error = error;
  }

  clearError() {
    this.error = "";
  }

  resetForm() {
    this.loading = false;
  }

  google() {
    this.authenticationService.loginWithGoogle()
      .then(() => this.next())
      .catch(error => this.handlerError(error.message))
      .finally(() => this.resetForm());
  }

  googleWithRedirect() {
    this.authenticationService.loginWithGoogleWithRedirect()
      .then(() => this.next())
      .catch(error => this.handlerError(error.message))
      .finally(() => this.resetForm());
  }

  redirectToUrl(url: string) {
    const parsedUrl = new URL(url, window.location.origin);

    const route = parsedUrl.pathname;

    const queryParams: { [key: string]: string } = {};
    parsedUrl.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    this.router.navigate([route], { queryParams });
  }

}
